import { useEffect, useState } from "react";
import OverviewViewModel from "../interfaces/OverviewViewModel";
import { Bubble, Scatter } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

interface PaceDistanceHrProps {
    startDate: string;
    endDate: string;
  }

  function convertDecimalToMinutes(pace: number) {
    const minutes = Math.floor(pace);
    const seconds = Math.round((pace - minutes) * 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}min/km`;
  }


const PaceDistanceHr: React.FC<PaceDistanceHrProps> = ({ startDate, endDate }) => {

    const [overviews, setOverviews] = useState<OverviewViewModel[]>([])
  
    useEffect(() => {
      const fetchHrHistogram = async () => {
        try {
          const response = await fetch(`https://runanalytics.azurewebsites.net/overview?from=${startDate}&to=${endDate}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data: OverviewViewModel[] = await response.json();
          setOverviews(data);
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
  
      fetchHrHistogram();
    }, [, startDate, endDate])
  
      const options = {
        scales: {
            x: {
              title: {
                display: true,
                text: 'Distanse (km)'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Pace (min/km)'
              }
            }
          },
        plugins: {
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: 'Utendørs Distanse, Pace, HR',
            },
            tooltip: {
                callbacks: {
                  label: function(context : any) {                                        
                    return [
                      `Distanse: ${context.raw.x.toFixed(2)} km`,
                      `Pace: ${convertDecimalToMinutes(context.raw.y)}`,
                      `Hjertefrekvens: ${context.raw.orgHR.toFixed(0)} bpm`,
                      `Dato: ${context.raw.d}`
                    ];
                  }
                }
              }
          }
      }

      const data = {
        labels: [],
        datasets: [
            {
                label: 'Terskeløkter',
                data: overviews?.filter(d => !d.treadmill && d.thresholdActivity).map(d => ({
                    x: d.distance,
                    y: d.pace,
                    r: (d.averageHeartRate - 130) / 3,
                    orgHR: d.averageHeartRate,
                    d: d.date,
                }))
            },
          {
            label: 'Andre',
            data: overviews?.filter(d => !d.treadmill && !d.thresholdActivity).map(d => ({
                x: d.distance,
                y: d.pace,
                r: (d.averageHeartRate - 130) / 3,
                orgHR: d.averageHeartRate,
                d: d.date,
            }))
          },
        ],
      };
  
      console.log(data);
      return <> 
        <Bubble
        data={data}
        options={options}
        />
      </>
  }
  
  export default PaceDistanceHr;