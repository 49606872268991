import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import DistanceWeekly from "./components/distance";
import HeartRateHistogram from "./components/HeartRateHistogram";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Zones from "./components/zones";
import Utvikling from "./components/utvikling";
import KeyMetrics from "./components/keymetrics";
import PaceDistanceHr from "./components/paceDistanceHr";
import DurationInfo from "./components/duration";
import Map from "./components/map";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
}));

function Dashboard() {

    const [fromDate, setFromDate] = useState<Dayjs>(dayjs('2023-10-01'));
    const [toDate, setToDate] = useState<Dayjs>(dayjs());


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                padding: '4em',
                maxWidth: '1600px',
            }}>
            <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={12}>
            <div style={{
                display: 'flex', // Aktiverer flexbox
                flexDirection: 'row', // Ordner barna horisontalt
                alignItems: 'flex-start', // Plasserer barna til venstre
            }}>
                <div style={{
                    marginRight: '1em'
                }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        label="Fra"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue ?? dayjs('2023-10-01'))}
                        />
                    </LocalizationProvider>
                </div>
                    <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        label="Til"
                        value={toDate}
                        onChange={(newValue) => setToDate(newValue ?? dayjs())}
                        />
                    </LocalizationProvider>
                    </div>
                </div>
                <Grid item xs={12}>
                    <KeyMetrics startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Grid>
                </Grid>
                <Grid item xs={6}>
                <Item>
                    <DistanceWeekly startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
                <Grid item xs={6}>
                <Item>
                    <DurationInfo startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
                <Grid item xs={6}>
                    
                <Item>
                    <Utvikling />
                </Item>
                </Grid>
                <Grid item xs={8}>
                <Item>
                    <HeartRateHistogram startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
                <Grid item xs={4}>
                <Item>
                    <Zones  startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
                <Grid item xs={6}>
                <Item>
                    <PaceDistanceHr startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
                <Grid item xs={12}>
                <Item>
                    <Map startDate={fromDate.toISOString()} endDate={toDate.toISOString()}/>
                </Item>
                </Grid>
            </Grid>
            </div>
        </div>
    );
  }


export default Dashboard;


