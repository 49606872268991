import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import IHeartRateHistogram from '../interfaces/HeartRateHistogram';
import { FormControlLabel, Switch } from '@mui/material';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(...registerables, annotationPlugin);

function generateDateLabels(): number[] {

    let series: number[] = []
    for(var i = 35; i < 200; i++){
        series.push(i);
    }
  
    return series;
  }
  
  const labels = generateDateLabels()

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Pulshistogram',
      }
    },
    scales:{
      x :{
        time : {
          
        }
      }
    }
  };

  interface HeartRateHistogramProps {
    startDate: string;
    endDate: string;
  }

const HeartRateHistogram: React.FC<HeartRateHistogramProps> = ({ startDate, endDate }) => {

  const [heartRateHistogram, setheartRateHistogram] = useState<IHeartRateHistogram[]>([])
  const [asPercentage, setAsPercentage] = useState(true);

  useEffect(() => {
    const fetchHrHistogram = async () => {
      try {
        const response = await fetch(`https://runanalytics.azurewebsites.net/hrhistogram?from=${startDate}&to=${endDate}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: IHeartRateHistogram[] = await response.json();
        setheartRateHistogram(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }

    fetchHrHistogram();
  }, [, startDate, endDate])

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Antall slag',
          data: heartRateHistogram.map(d => ({
            x: d.heartRate,
            y: asPercentage ? (d.count / heartRateHistogram.reduce((a, v) => a + v.count, 0)) * 100  : d.count
          })),
          borderColor: 'rgb(200, 50, 50)',
          backgroundColor: 'rgba(200, 50, 50, 0.5)',
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          borderWidth: 1,
          borderRadius: 3
        },
      ],
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAsPercentage(event.target.checked);
    };

    return <>
    <div style={{height: '100%'}}>
        <Bar data={data} options={options}/>
        <FormControlLabel 
          label = "Som prosentandel"
          control = {<Switch checked={asPercentage} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>}
        />
        </div>
    </>
}

export default HeartRateHistogram;