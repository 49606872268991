import { Line } from "react-chartjs-2";

const Utvikling: React.FC = () => {
  
    const labels = ['2023-10-01', '2023-11-01', '2023-12-01', '2024-01-01', '2024-02-01', '2024-03-01', '2024-04-01', '2024-05-01', '2024-06-01', '2024-07-01', '2024-08-01', '2024-09-01', '2024-10-01'];

     const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: '1Km intervalltempo',
          }
        },
        scales:{
          x :{
            time : {
              
            }
          }
        }
      };

      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Plan anno Sept 2023',
            data: [{
                x: '2023-10-01',
                y: 4.8333
            },
            {
                x: '2023-11-01',
                y: 4.6667
            },{
                x: '2023-12-01',
                y: 4.5
            },{
                x: '2024-01-01',
                y: 4.3333
            },{
                x: '2024-02-01',
                y: 4.25
            },{
                x: '2024-03-01',
                y: 4.166667
            },{
                x: '2024-04-01',
                y: 4.08333
            },{
                x: '2024-05-01',
                y: 4.0
            }],
            borderWidth: 1,
            borderRadius: 3
          },
          {
            label: 'Faktisk',
            data: [{
                x: '2023-10-01',
                y: 4.916667
            },
            {
                x: '2023-11-01',
                y: 4.75
            },{
                x: '2023-12-01',
                y: 4.56667
            },{
                x: '2024-01-01',
                y: 4.4
            },{
                x: '2024-02-01',
                y: 4.25
            },{
                x: '2024-03-01',
                y: 4.183333
            },{
                x: '2024-04-01',
                y: 4.066667
            }],
            borderWidth: 1,
            borderRadius: 3
          },
          {
            label: 'Plan anno April 2024',
            data: [
                {
                    x: '2024-05-01',
                    y: 4.0
                },
                {
                    x: '2024-06-01',
                    y: 3 + 57/60
                },
                {
                    x: '2024-07-01',
                    y: 3 + 54/60
                },
                {
                    x: '2024-08-01',
                    y: 3 + 52/60
                },
            ],
            borderWidth: 1,
            borderRadius: 3
          },
        ],
      };

      return <> 
        <Line
            data={data}
            options={options}
        />
      </>
  }
  
  export default Utvikling;