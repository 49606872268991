import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Box, Slider } from '@mui/material';
ChartJS.register(...registerables);

interface DailyDuration{
    date: string,
    minutes: number,
    treadmill: boolean
}

interface WeeklyDuration {
  label: string; // År og ukenummer, f.eks. "2024-Uke 1"
  distance: number;
}

interface MonthlyDuration {
  label: string; // År og måned, f.eks. "2024-Januar"
  distance: number;
}

function generateDateLabels(startDate: string, endDate: string): string[] {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const labels: string[] = [];

  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    labels.push(date.toISOString().split('T')[0]); // Formatterer datoen som 'YYYY-MM-DD'
  }

  // Tilbakestill startdatoen siden `setDate` endrer den opprinnelige datoen
  start.setDate(start.getDate() - 1);

  return labels;
}

const labels = generateDateLabels('2023-01-01', '2024-05-05')

function getYearAndWeekNumber(dateStr: string): { year: number; week: number } {
  const date = new Date(dateStr);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const yearStart = new Date(date.getFullYear(), 0, 1);
  const weekNo = Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  return { year: date.getFullYear(), week: weekNo };
}

function sumDistanceByWeekIncludingZeros(dailyDurations: DailyDuration[]): WeeklyDuration[] {
  if(dailyDurations.length === 0) return [];
  
  const sortedDistances = dailyDurations.sort((a, b) => a.date.localeCompare(b.date));
  const startWeek = getYearAndWeekNumber(sortedDistances[0].date);
  const endWeek = getYearAndWeekNumber(sortedDistances[sortedDistances.length - 1].date);
  let currentWeek = startWeek.week;
  let currentYear = startWeek.year;

  const weeklyDurations: WeeklyDuration[] = [];

  while(currentYear < endWeek.year || (currentYear === endWeek.year && currentWeek <= endWeek.week)) {
      weeklyDurations.push({
          label: `${currentYear}-Uke ${currentWeek}`,
          distance: 0
      });
      
      currentWeek++;
      if(currentWeek > 52) {
          currentWeek = 1;
          currentYear++;
      }
  }

  dailyDurations.forEach(({ date, minutes }) => {
      const { year, week } = getYearAndWeekNumber(date);
      const weekLabel = `${year}-Uke ${week}`;
      const weeklyDistance = weeklyDurations.find(wd => wd.label === weekLabel);
      if(weeklyDistance) {
          weeklyDistance.distance += minutes;
      }
  });

  return weeklyDurations;
}

function sumDistanceByMonth(dailyDurations: DailyDuration[]): MonthlyDuration[] {
  const monthlySum: { [key: string]: number } = {};

  dailyDurations.forEach(({ date, minutes }) => {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      // Bruker +1 siden getMonth() returnerer måned 0-11
      const month = dateObj.getMonth() + 1;
      const key = `${year}-${month.toString().padStart(2, '0')}`; // Format: YYYY-MM

      if (!monthlySum[key]) {
          monthlySum[key] = 0;
      }
      monthlySum[key] += minutes;
  });

  return Object.keys(monthlySum).map(key => {
      const [year, month] = key.split('-');
      const monthName = new Date(parseInt(year), parseInt(month) - 1, 1).toLocaleString('nb-NO', { month: 'long' });
      return {
          label: `${year}-${monthName}`,
          distance: monthlySum[key],
      };
  });
}

export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Tid',
      },
      tooltip: {
        callbacks: {
          label: function(context : any) {                                        
            return [
              `Tid: ${context.raw.y} min`,
              `Dato: ${context.raw.x}`
            ];
          }
        }
      }
    },
    scales:{
      x : {
        stacked: true,
        time : {
          
        }
      },
      y: {
        stacked: true
      }
    }
  };

  interface DistanceWeeklyProps {
    startDate: string;
    endDate: string;
  }

const DurationInfo: React.FC<DistanceWeeklyProps> = ({ startDate, endDate }) => {

  const [dailyDurations, setDailyDurations] = useState<DailyDuration[]>([])
  const [horizon, setHorizon] = useState(1)

  useEffect(() => {
    const fetchDistances = async () => {
      try {
        const response = await fetch(`https://runanalytics.azurewebsites.net/durations?from=${startDate}&to=${endDate}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: DailyDuration[] = await response.json();
        setDailyDurations(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }

    fetchDistances();
  }, [, startDate, endDate])

    const dailydata = {
      labels: generateDateLabels(startDate, endDate),
      datasets: [
        {
          label: 'Mølle',
          data: dailyDurations.filter(d => d.treadmill).map(d => ({
            x: d.date,
            y: d.minutes
          })),
          borderColor: 'rgb(235, 162, 50)',
          backgroundColor: 'rgba(235, 162, 50, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
        {
          label: 'Utendørs',
          data: dailyDurations.filter(d => !d.treadmill).map(d => ({
            x: d.date,
            y: d.minutes
          })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
      ],
    };

    const weeklyData = {
      labels: sumDistanceByWeekIncludingZeros(dailyDurations).map(x => x.label),
      datasets: [
        {
          label: 'Mølle',
          data: sumDistanceByWeekIncludingZeros(dailyDurations.filter(d => d.treadmill)).map(d => ({
            x: d.label,
            y: d.distance
          })),
          borderColor: 'rgb(235, 162, 50)',
          backgroundColor: 'rgba(235, 162, 50, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
        {
          label: 'Utendørs',
          data: sumDistanceByWeekIncludingZeros(dailyDurations.filter(d => !d.treadmill)).map(d => ({
            x: d.label,
            y: d.distance
          })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
      ],
    };

    const monthlyData = {
      labels: sumDistanceByMonth(dailyDurations).map(x => x.label),
      datasets: [
        {
          label: 'Mølle',
          data: sumDistanceByMonth(dailyDurations.filter(d => d.treadmill)).map(d => ({
            x: d.label,
            y: d.distance
          })),
          borderColor: 'rgb(235, 162, 50)',
          backgroundColor: 'rgba(235, 162, 50, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
        {
          label: 'Utendørs',
          data: sumDistanceByMonth(dailyDurations.filter(d => !d.treadmill)).map(d => ({
            x: d.label,
            y: d.distance
          })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderWidth: 2,
          borderRadius: 3
        },
      ],
    };

    const slidermarks = [
      {
        value: 1,
        label: 'Dag',
      },
      {
        value: 2,
        label: 'Uke',
      },
      {
        value: 3,
        label: 'Måned',
      },
    ];

    const handleChange = (event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        setHorizon(newValue);
      }
    };

    return <>
        <Bar data={horizon == 1 ? dailydata : (horizon == 2 ? weeklyData : monthlyData)} options={options}/>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '30%' }}>
            <Slider
              defaultValue={1}
              valueLabelDisplay="off"
              shiftStep={1}
              step={1}
              marks={slidermarks}
              min={1}
              max={3}
              onChange={handleChange}
              value={horizon}    
            />
          </Box>
        </div>
        
    </>
}

export default DurationInfo;