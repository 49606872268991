import { useEffect, useState } from "react";
import IZones from "../interfaces/IZones";
import { Doughnut } from "react-chartjs-2";

interface ZonesProps {
    startDate: string;
    endDate: string;
  }

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Tid i soner',
      }
    }
  };

const Zones: React.FC<ZonesProps> = ({ startDate, endDate }) => {

    const [zones, setZones] = useState<IZones>()
  
    useEffect(() => {
      const fetchHrHistogram = async () => {
        try {
          const response = await fetch(`https://runanalytics.azurewebsites.net/zones?from=${startDate}&to=${endDate}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data: IZones = await response.json();
          setZones(data);
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      }
  
      fetchHrHistogram();
    }, [, startDate, endDate])
  
      const data = {
        labels: ['Sone 1', 'Sone 2', 'Sone 3', 'Sone 4', 'Sone 5'],
        datasets: [
          {
            label: 'Antall sekunder',
            data: [zones?.zone1, zones?.zone2, zones?.zone3, zones?.zone4, zones?.zone5],
            backgroundColor: [
                'rgba(213, 213, 212, 0.2)',
                'rgba(20, 200, 50, 0.4)',
                'rgba(20, 50, 200, 0.6)',
                'rgba(255, 144, 71, 0.8)',
                'rgba(255, 0, 0, 0.9)',
              ],
              borderColor: [
                'rgba(213, 213, 212, 0.8)',
                'rgba(20, 200, 50, 0.2)',
                'rgba(20, 50, 200, 0.2)',
                'rgba(255, 144, 71 0.8)',
                'rgba(255, 0, 0, 0.9)',
              ],
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            borderWidth: 1,
            borderRadius: 3
          },
        ],
      };
  
      console.log(data);
      return <> 
        <Doughnut
            options={options}
            data={data}
        />
      </>
  }
  
  export default Zones;