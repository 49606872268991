import { useEffect, useState } from "react";

interface KeyMetricsProps {
    startDate: string;
    endDate: string;
  }

interface KeyMetricsViewModel{
    totalTime: number,
    totalDistance: number,
    timeInThreshold: number,
    thresholdPercentage: number
}

interface KeyMetricProps{
    label: string,
    value: string
}

function minutterTilTimerOgMinutter(minutter: number | undefined): string {
    // Beregner antall hele timer i det gitte antallet minutter

    if(minutter == undefined){
        return '-';
    }

    const timer = Math.floor(minutter / 60);

    // Beregner gjenværende minutter etter å ha fjernet de hele timene
    const gjenværendeMinutter = minutter % 60;

    // Returnerer en formatert streng som viser timer og minutter
    return `${timer} timer og ${gjenværendeMinutter} minutter`;
}

function tilProsentandel(tall: number | undefined): string {
    if(tall == undefined){
        return '-'
    }
    
    const formatertTall = (tall * 100).toFixed(2);
    return formatertTall + "%";
}

function tilNarmesteKilometer(tall: number | undefined): string {
    if(tall == undefined){
        return '-'
    }
    
    const formatertTall = tall.toFixed(0);
    return formatertTall + "Km";
}

const KeyMetric: React.FC<KeyMetricProps> = ({label, value}) => {
    return <>
    <div style={{
        width: '250px',
        height: '120px',
        margin: '2em',
        paddingTop: '1em',
        borderRadius: '3px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        
    }}>
        <p style={{
            margin: 0,
            padding: 0,
            fontSize: '20pt',
            color: 'gray'
        }}>{label}</p>
        <br/>
        <p style={{
            margin: 0,
            padding: 0,
            color: 'gray',
            fontSize: '15pt'
        }}>{value}</p>
    </div>

    </>
}

const KeyMetrics: React.FC<KeyMetricsProps> = ({ startDate, endDate }) => {

  const [keyMetrics, setKeyMetrics] = useState<KeyMetricsViewModel>();

  useEffect(() => {
    const fetchHrHistogram = async () => {
      try {
        const response = await fetch(`https://runanalytics.azurewebsites.net/keymetrics?from=${startDate}&to=${endDate}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: KeyMetricsViewModel = await response.json();
        setKeyMetrics(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    }

    fetchHrHistogram();
  }, [, startDate, endDate])


    return <>
    <div style={{height: '100%', display: 'flex', justifyContent: 'center'}}>
        <KeyMetric label="Treningstid" value={minutterTilTimerOgMinutter(keyMetrics?.totalTime)}/>
        <KeyMetric label="Tid i terskel" value={minutterTilTimerOgMinutter(keyMetrics?.timeInThreshold)}/>
        <KeyMetric label="Andel i terskel" value={tilProsentandel(keyMetrics?.thresholdPercentage)}/>
        <KeyMetric label="Total distanse" value={tilNarmesteKilometer(keyMetrics?.totalDistance)}/>
        
    </div>
    
    </>
}

export default KeyMetrics;